import finesApi from '@rosfines/vue-common/common/api/finesApi';

let licenseURLRequest;

export default {
  namespaced: true,
  state () {
    return {
      licenseURL: '',
      activeTests: [],
      segment: '',
    }
  },
  getters: {
    isTestActive: (state) => (testName) => {
      return state.activeTests.includes(testName)
    },
  },
  mutations: {
    saveLicenseURL(state, val) {
      state.licenseURL = val;
    },
    addActiveTest(state, testName) {
      if (!state.activeTests.includes(testName)) {
        state.activeTests.push(testName)
      }
    },
    setSegment(state, segment) {
      state.segment = segment;
    },
  },
  actions: {
    async getLicenseURL({dispatch}) {
      if (!licenseURLRequest) {
        licenseURLRequest = dispatch('_getLicenseURL');
      }
      return licenseURLRequest;

    },
    async _getLicenseURL({commit}) {
      const url = await finesApi.get('document/offer').catch(() => {})
      if (finesApi.isSuccess(url)) {
        commit("saveLicenseURL", url.data.response.url)
      }
      return url;
    }
  },
}