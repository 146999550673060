import finesApi from '@rosfines/vue-common/common/api/finesApi';
import BankCardsHelper from '@rosfines/core-web/helpers/BankCards';
import tracking from '@/utils/tracking';

export default {
  namespaced: true,
  state () {
    return {
      paymentTypes: [],
      payment: {
        rememberCard: false,
        paymentType: 0,
        type: 'fine',
        paymentSystem: 'A3'
      },
      partialAmount: 0,
    }
  },
  getters: {
    getPartialAmount(state) {
      return state.partialAmount
    },
    isPartialPayment(state) {
      return state.partialAmount > 0
    }
  },
  mutations: {
    setPaymentTypes(state, types) {
      state.paymentTypes = types;
    },
    changePaymentType(state, {
      paymentType,
      rememberCard,
    }) {
      state.payment.paymentType = paymentType;
      state.payment.rememberCard = rememberCard;
    },
    setPaymentType(state, type) {
      if (type) {
        state.payment.type = type;
      }
    },
    setRememberCard(state, value) {
      state.payment.rememberCard = !!value;
    },
    setPaymentSystem(state, value) {
      state.payment.paymentSystem = value;
    },
    setPartialPaymentAmount(state, { partialAmount }) {
      partialAmount = Number(partialAmount) > 0 ? Number(partialAmount) : 0
      state.partialAmount = partialAmount
    },
  },
  actions: {
    async getPSForOrdinances({state, rootState, rootGetters, commit}) {
      const ids = state.payment.type === 'tax' ?  rootState.taxes.chosenOrdinancesIds : rootState.fines.chosenOrdinancesIds;
      const response = await finesApi.getPSForOrdinances({
        type: state.payment.type,
        ids,
      })
      if (response) {
        if(rootGetters['app/isTestActive']('test-add-serv')) {
          if (response.availablePaymentSystems.includes('moneta')) {
            commit("setPaymentSystem", 'moneta');
          } else {
            commit("setPaymentSystem", response.paymentSystem);
            tracking.trackEvent('Fines_webview_experiment', {
              abtest: 'test-add-serv',
              cohort: this.$route.query.commissionViewTest,
              action: 'moneta-isnt-avaliable',
              addText2: rootState.app.segment,
            })
          }
        } else {
          commit("setPaymentSystem", response.paymentSystem);
        }
      }
    },
    async getRecurringInfo ({state, commit}, {paymentSystem, isTax}) {
      const cardsResponse = await finesApi.get('https://payment.rosfines.ru/recurring/info', {
        paymentSystem: state.payment.paymentSystem,
        isTax,
      }).catch(() => {
        console.error('Error while fetching recurring info')
      })

      const paymentTypes = []
      if (finesApi.isSuccess(cardsResponse)) {
        cardsResponse.data.response.cards.forEach((card) => {
          paymentTypes.push({
            type: 'card',
            title: '•••• ' + card.card.substr(card.card.length - 4),
            transactionId: card.transaction_id,
            icon: BankCardsHelper.getCardname(card.card) || 'card'
          })
        })
      }
      paymentTypes.push({ type: 'card', title: 'Банковская карта', icon: 'card' })
      paymentTypes.push({ type: 'SBP', title: 'Система быстрых платежей', icon: 'sbp' })
      commit("setPaymentTypes", paymentTypes);
      if (!paymentTypes[0].transactionId) {
        commit("setRememberCard", true);
      }

      return cardsResponse;
    },
  },
}